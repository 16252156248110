import React from 'react';
import { MoThemeProvider } from '@movember/mo-gel';
import { HomePage } from './pages/Home/Home.page';
import { RootStyle, StyledApp } from './App.styled';
import { AheadOfTheGame } from './themes/AheadOfTheGame.theme';
import { Header } from './components/Header/Header';
import { Switch, Route } from 'react-router';
import { AboutPage } from './pages/About';
import { ParentPage } from './pages/About/Parent/Parent.page';
import { AthletePage } from './pages/About/Athlete/Athlete.page';
import { CoachPage } from './pages/About/Coach/Coach.page';
import { GetHelpPage } from './pages/GetHelp/GetHelp.page';
// import { SignupPage } from './pages/Signup/Signup.page';
import { ResearchPage } from './pages/Research/Research.page';
// import { LoginPage } from './pages/Login/Login.page';
import { ContactUsPage } from './pages/ContactUs/ContactUs.page';
import { FaqPage } from './pages/Faq/Faq.page';
import { PrivacyPage } from './pages/Privacy/PrivacyPage';
import { Footer } from './components/Footer/Footer';
import { TermsConditionsPage } from './pages/TermsConditions/TermsConditionsPage';
import { PartnerList } from './components/Partners/PartnerList'
import { DocumentaryPage } from './pages/Documentary/Documentary.page';

const App: React.FC = () => { 
  return (
    <MoThemeProvider theme={AheadOfTheGame}>
      <StyledApp>
        <RootStyle />
        <Header />
        <Switch>
          <Route exact={true} path="/" component={HomePage}/>
          {/* <Route exact={true} path="/foo" component={HomePage}/> */}
          <Route exact={true} path="/about" component={AboutPage}/>
          <Route path="/whositfor/parent" component={ParentPage}/>
          <Route path="/whositfor/athlete" component={AthletePage}/>
          <Route path="/whositfor/coach" component={CoachPage}/>
          <Route path="/gethelp" component={GetHelpPage} />
          <Route path="/research" component={ResearchPage} />
          <Route path="/documentary" component={DocumentaryPage} />
          <Route path="/contact" component={ContactUsPage} />
          <Route path="/faq" component={FaqPage} />
          <Route path="/signup" component={ContactUsPage} />
          {/* <Route path="/login" component={LoginPage} /> */}
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/terms" component={TermsConditionsPage} />
        </Switch>
        <PartnerList />
        <Footer />
      </StyledApp>
    </MoThemeProvider>
  );
};

export default App;
